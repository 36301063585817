import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../../components/seo';
import PrimaryLayout from '../../components/layout/primary/primary';
import Amplify, { Auth } from 'aws-amplify';
import AuthStateApp from '../../components/Authenticator/AuthStateApp'
import Profile from '../../components/profile/profile';
import awsconfig from '../../aws-exports';

Amplify.configure(awsconfig);
async function signOut() {
	try {
		//await Amplify.Auth.signOut({ global: true });
		await Amplify.Auth.signOut();
	} catch (error) {
		console.log('error signing out: ', error);
	}
}

const ProfilePage: React.FC<{}> = (props: any) => {

console.log(`profile props: ${JSON.stringify(props)}`)
const { location } = props; 

if(typeof window !== `undefined` && location) {
	if (location?.state?.logoff) {
		signOut();
	}
}
return (
	
	<PrimaryLayout>
		<SEO title="Chat2order Shops Profile" />
		<Container py="10px">
		<AuthStateApp>
			< Profile />
		</AuthStateApp>	
		</Container>
	</PrimaryLayout>
	
);
}

export default ProfilePage;
