/** @jsx jsx */
import React from 'react';
import {  AmplifySignOut } from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { Grid, Box, jsx } from 'theme-ui';
import { animated, useSpring, useChain } from 'react-spring';
import Order from './Order';
import styles from './profile.style';
import ProfileBlocks from '../profile-blocks/primary/primary';
import { StaticQuery, graphql } from 'gatsby';


const profileStaticQuery = graphql`
	query {
	  allFile(filter: {extension: {eq: "png"}, relativeDirectory: {eq: "profile"}, base: {in: ["1-order.png", "2-details.png", "3-signout.png"]}}) {
		edges {
		  node {
			id
			base
			childImageSharp {
			  id
			  fluid{
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		  }
		}
	  }
	}
`;

const Profile = ({user}) => {
	return (
		<StaticQuery
		  query={`${profileStaticQuery}`}
		  render={data => (
			<ProfileBlocks profileBlocks={data.allFile}/>
		  )}
		/>	
	)
}


export default Profile;